import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import preval from 'preval.macro';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath:
        (window as any).CACxBotOptions?.url ||
        '' +
          '/locales/{{lng}}/{{ns}}.json?' +
          preval(`module.exports = new Date().getTime()` as any),
    },
    detection: {
      order: [
        'querystring',
        // 'localStorage', temporarily disabled
        'navigator',
      ],
    },
    load: 'languageOnly',
    ns: ['brands', 'candidateCapture', 'conversation', 'interview', 'feedback'],
    fallbackLng: 'en',
    debug: false,
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
