import cn from 'classnames';
import { Provider } from 'react-redux';
import store from '@redux/store';
import tagStyles from '@common/assets/styles/main.scss';

import styles from './app.module.scss';
import Routes from './routes';

function App() {
  return (
    <div className={cn(tagStyles.tagDs, styles.app)}>
      <Provider store={store}>
        <Routes />
      </Provider>
    </div>
  );
}

export default App;
